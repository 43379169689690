// @format
export const theme = {
  fonts: {
    heading: "'Futura Md BT', 'Average Sans', sans-serif",
    monospace: "'Source Code Pro', monospace",
  },
  colors: {
    background: '#ededed',
    text: '#494949',
    primary: '#0F505D',
    accent: '#0b7678',
    pre: '#494949',
  },
  styles: {
    body: {
      width: 'initial',
      padding: 0,
    },
    h1: {
      color: 'primary',
      textTransform: 'uppercase',
    },
    h2: {
      color: 'primary',
    },
  },
};
